import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { fbContext } from "./Firebase";

export default (props: any) => {
    const { component: Component, ...rest } = props;
    const { authUser, ready } = React.useContext(fbContext);

    const renderPage = () => {
        if (!ready) {
            return <div />
        } else {
            return <Route {...rest} render={(routeProps: JSX.IntrinsicAttributes) => {
                if (authUser) {
                    return <Component {...routeProps} />
                }
                else {
                    return <Redirect to={"/"} />
                }
            }} />
        }
    }
    return renderPage();
}
