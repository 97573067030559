import React from 'react'
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export default (props: any) => {
    const { selectedDate, setSelectedDate } = props;
    let now = new Date()
    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
        style={{width:'150px'}}
        clearable
        label="Choose Date"
        value={selectedDate}
        onChange={date => setSelectedDate(date)}
        minDate={new Date()}
        maxDate={now.setDate(now.getDate() + 14)}
        format="DD/MM/yyyy"
    />
    </MuiPickersUtilsProvider>
}


