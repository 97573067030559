import React from 'react';
import {Button, Box, Typography, Divider, Avatar} from '@material-ui/core';
import {db, fbContext, getFireBaseTimeStampFromDate} from "../../Firebase";
import DatePicker from "./DatePicker";
import BookingTable from "./BookingTable";

export default (props: any) => {
    const {signOutAuth, authUser} = React.useContext(fbContext);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [template, setTemplate] = React.useState<any>([]);
    const [bookings, setBookings] = React.useState<any>([]);

    const logout = async (_e: any) => await signOutAuth();

    const checkIfAlreadyBookedToday = () => {
        if (bookings.findIndex((x: any) => x.userId === authUser.uid) >= 0) {
            return true
        }
        return false
    }

    const bookTable = async (tableId: string) => {
        if (!checkIfAlreadyBookedToday()) {
            await db
                .collection("offices")
                .doc("london")
                .collection("bookings")
                .add({
                    date: getFireBaseTimeStampFromDate(selectedDate),
                    table: db.collection("offices").doc("london").collection("tables").doc(tableId),
                    userId: authUser.uid,
                    userName: authUser.displayName
                })
        } else {
            alert("You can only book one table per day")
        }

    }

    const cancelReservation = async (id: string) => {
        await db
            .collection("offices")
            .doc("london")
            .collection("bookings")
            .doc(id)
            .delete();
    }

    React.useEffect(() => {
        if (authUser) {
            const getTemplatesFromDB = async () => {
                const querySnapShot = await db
                    .collection("offices")
                    .doc("london")
                    .collection("tables")
                    .orderBy("tableNum", "asc")
                    .get()
                let items: any[] = []
                querySnapShot.forEach((doc: any) => {
                    const item = doc.data();
                    item["id"] = doc.id;
                    items.push(item);
                });
                setTemplate(items)
            }
            getTemplatesFromDB();
        }
    }, [authUser])

    React.useEffect(() => {
        let unsubscribe: any
        if (selectedDate && authUser && template.length > 0) {
            unsubscribe = db
                .collection("offices")
                .doc("london")
                .collection("bookings")
                .where("date", "==", getFireBaseTimeStampFromDate(selectedDate))
                .onSnapshot((result) => {
                    let items: any = []
                    result.forEach((doc: any) => {
                        let item = doc.data()
                        item["id"] = doc.id;
                        items.push(item)
                    });
                    setBookings(items)
                })

        } else {
            if (unsubscribe) {
                unsubscribe()
            }
        }

    }, [selectedDate, authUser, template])

    return <Box margin={2}>
        <Box display="flex" mb={3} alignItems="flex-end">
            <Box mr={2}>
                <img
                    src="logo.png"
                    width="105"
                    height="24"
                    alt="Satalia logo"
                />
            </Box>
            <Box mx={0} style={{top: 3, position: 'relative'}}>
                <Typography color="primary" variant="h5" style={{display: 'inline-block'}}>Desk Booking</Typography>
            </Box>
            <Box mx="auto"/>
            <Box display="flex" height="40px" alignItems="center">
                <DatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                <Box mx="16px"/>
                <Avatar alt={authUser.displayName} src={authUser.photoURL}/>
                <Box mx="16px"/>
                <Button style={{textTransform: 'capitalize', borderRadius: 0}} variant="contained" color="primary" onClick={logout}>Sign
                    out</Button>
            </Box>
        </Box>
        <Divider/>
        <Box my={2}/>
        {selectedDate && template.length > 0
            ? <BookingTable template={template} bookings={bookings} bookTable={bookTable}
                            cancelReservation={cancelReservation}/>
            : null
        }
    </Box>

}
