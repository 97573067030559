import React from 'react';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import AuthoriseRoute from './AuthoriseRoute';

// @ts-ignore
const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#000000",
      // dark: will be calculated from palette.primary.main,
    },
    secondary: {
      main: "#ff002a",

    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexe s within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  shadows: Array(25),
});

function App() {
  return <div style={{ height: "100%", width: "100%", position: "fixed", overflowY: "scroll" }}>
    <ThemeProvider theme={theme}>
      <BrowserRouter >
        <Switch>
          <AuthoriseRoute exact path="/home" component={Dashboard} />
          <Route path="/" component={Login} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  </div>

}

export default App;
