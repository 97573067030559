import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { fbContext } from "../../Firebase";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    row: {
        height: 50
    }
});


export default (props: any) => {
    const { bookings } = props;
    const classes = useStyles();
    const { authUser } = React.useContext(fbContext);

    const checkIfBooked = React.useCallback((tableName: string) => {
        const bookingIndex = bookings.findIndex((x: any) => x.table.id === tableName)
        if (bookingIndex >= 0) {
            return `Reserved by ${bookings[bookingIndex].userName}`
        }
        return "Available"

    }, [bookings])

    const showBookUnBookButton = React.useCallback((tableName: string) => {
        const bookingIndex = bookings.findIndex((x: any) => x.table.id === tableName)
        if (bookingIndex < 0) {
            return <Button style={{textTransform: 'capitalize', width:'80px', borderRadius: 0}} onClick={() => props.bookTable(tableName)} variant="contained" color="primary">Book</Button>
        }
        if (bookings[bookingIndex].userId === authUser.uid) {
            return <Button style={{textTransform: 'capitalize', width:'80px', borderRadius: 0}} onClick={() => props.cancelReservation(bookings[bookingIndex].id)} variant="contained" color="secondary">cancel</Button>
        }
        return

    }, [bookings])


    return (
        <TableContainer component={Paper} >
            <Table  size="small" className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Desk ID</TableCell>
                        <TableCell align="center">Room</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left"></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody >
                    {props.template.map((row: any) => (
                        <TableRow className={classes.row} key={row.id}>
                            <TableCell width={"25%"} style={{ textTransform: 'capitalize' }} component="th" scope="row">
                                {row.tableNum}
                            </TableCell>
                            <TableCell align="center" width={"25%"}>{row.room}</TableCell>
                            <TableCell align="left" width={"25%"}>{checkIfBooked(row.id)}</TableCell>
                            <TableCell align="left" width={"25%"}>{showBookUnBookButton(row.id)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

