import React from 'react';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import moment from "moment";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};


firebase.initializeApp(firebaseConfig);

export const functions = firebase.app().functions("europe-west2");

if (process.env.NODE_ENV !== "production") {
    functions.useFunctionsEmulator('http://localhost:5001')
}
export const db = firebase.firestore();

export const callFirebaseFunction = async (functionName: string, dataObject: any) => {
    return await functions.httpsCallable(functionName)(dataObject);
};


const useFirebaseAuth = () => {
    const [fbAuth, setFbAuth] = React.useState<firebase.auth.Auth | undefined>();
    const [authUser, setAuthUser] = React.useState<firebase.User | null | undefined>();
    const [ready, setReady] = React.useState<boolean>(false);
    const [provider, setProvider] = React.useState<any>();

    React.useEffect(() => {
        setFbAuth(firebase.auth())
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            hd: 'satalia.com'
        })
        setProvider(provider)
    }, []);



    React.useEffect(() => {
        if (fbAuth) {
            setReady(false)
            const unsubscribe = fbAuth.onAuthStateChanged((user: any) => {
                if (user) {
                    setAuthUser(user);
                } else {
                    setAuthUser(null)
                }
                setReady(true);

            })
            return () => {
                unsubscribe()
            };
        }
        return

    }, [fbAuth]);


    const signInAuth = async () => {
        await fbAuth?.signInWithPopup(provider);
    }

    const signOutAuth = async () => {
        await fbAuth?.signOut();
    }

    return { ready, authUser, signInAuth, signOutAuth }
}

export const fbContext: any = React.createContext(null);

export const Fire = (props: any) => {
    const { ready, authUser, signInAuth, signOutAuth, } = useFirebaseAuth();

    return <fbContext.Provider value={{ ready, authUser, signInAuth, signOutAuth }}>
        {props.children}
    </fbContext.Provider>
}

export const getFireBaseTimeStamp = () => firebase.firestore.Timestamp.now();

export const getDateFromFireBaseTimeStamp = (timeStamp: firebase.firestore.Timestamp) => {
    return moment.unix(timeStamp.seconds).format("LL")
}

// Remove hours or mins to get midnight value. 
export const getMidightFireBaseTimeStampFromUTCDate = (dateString: any) => {
    let date = moment.utc(dateString).toDate()
    date.setUTCHours(0, 0, 0, 0);
    return firebase.firestore.Timestamp.fromDate(date);
}

// Remove hours or mins to get midnight value. 
export const getFireBaseTimeStampFromDate = (dateString: any) => {
    let date = moment(dateString).toDate()
    date.setHours(0, 0, 0, 0);
    return firebase.firestore.Timestamp.fromDate(date);
}

// Takes a date string, assumes it's in UTC so doesn't do any conversions to local. 
export const getFireBaseTimeStampFromUTCDate = (dateString: any) => {
    let date = moment.utc(dateString).toDate();
    return firebase.firestore.Timestamp.fromDate(date);
}

export const getFireBaseTimeStampFromDateTime = (dateTimeString: any) => {
    return firebase.firestore.Timestamp.fromDate(dateTimeString);
}

export const getFireBaseGeoPoint = (lat: any, lng: any) => {
    return new firebase.firestore.GeoPoint(parseFloat(lat), parseFloat(lng));
}