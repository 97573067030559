import React from 'react';
import { Button, Box, Paper, Typography } from '@material-ui/core';
import { fbContext } from "../../Firebase";

export default (props: any) => {
    const { signInAuth, authUser } = React.useContext(fbContext);

    const login = async (_e: any) => {
        try {
            await signInAuth();
        } catch (error) {
            console.log("error", error)
        }
    };

    React.useEffect(() => {
        if (authUser) { 
            props.history.push('/home');
        }
    }, [authUser, props.history])


    return <Box bgcolor="white" display="flex" height="100%" alignItems="center">
        <Box mx="auto" />
        <Paper elevation={3}>
            <Box height="100px" paddingX={2} >
                <Typography style={{ margin: '16px 0px' }} color="primary" variant="h5">Satalia Desk Booking</Typography>
                <Box textAlign="center">
                    <Button variant="contained" color="primary" onClick={login}>Login</Button>
                </Box>
            </Box>
        </Paper >
        <Box mx="auto" />
    </Box >
}
